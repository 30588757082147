import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { FocusModeService } from '@dep/frontend/shared/services/focus-mode.service';

@Component({
  selector: 'app-admin-root',
  templateUrl: './admin-root.component.html',
  styleUrls: ['./admin-root.component.scss'],
})
export class AdminRootComponent implements OnInit, OnDestroy {
  public isHomePage: boolean = false;
  public isFocusModeActive = false;
  private focusModeSub$?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly focusModeService: FocusModeService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is '/home' or if it's the root ('/')
        this.isHomePage = (event.url === '/home' || event.url === '/');
      }
    });
  }

  public ngOnInit(): void {
    this.focusModeSub$ = this.focusModeService.focusModeSubject.subscribe((isFocusModeActive: boolean) => {
      this.isFocusModeActive = isFocusModeActive;
    });
  }

  public ngOnDestroy(): void {
    this.focusModeSub$?.unsubscribe();
  }
}
